<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-text flat elevation="3">
            <order-search-bar
              v-model="newFilter"
              @clear="clearFilter"
              @submit="fetchRecords"
            />
          </v-card-text>
          <v-toolbar color="primary" flat>
            <v-spacer></v-spacer>
            <v-btn icon @click="fetchRecords(newFilter)">
              <v-icon color="white"> mdi-refresh</v-icon>
            </v-btn>
            <if-auth>
              <template #allowed="{ supervisor }">
                <v-btn v-if="supervisor" icon @click="createItem">
                  <v-icon color="white"> mdi-plus</v-icon>
                </v-btn>
              </template>
            </if-auth>
            <confirmation-dialog 
              v-model="dialogDelete"
              text="¿Estás seguro de que desea eliminar la orden de trabajo?"
              @confirmed="deleteItemConfirm"
              @input="closeDelete"
            />
            <confirmation-dialog 
              v-model="dialogUndo"
              text="¿Desea deshacer la órden de trabajo?"
              :max-width="400"
              @confirmed="handleRollback"
              @input="closeRollbackDialog"
            />
            <confirmation-dialog 
              v-model="dialogApprove"
              text="¿Estás seguro que deseas aprobar la órden de trabajo?"
              @confirmed="handleApprove"
              @input="closeApproveDialog"
            />
            <confirmation-dialog 
              v-model="dialogRevoke"
              text="¿Estás seguro que deseas rechazar la órden de trabajo?"
              @confirmed="handleRevoke"
              @input="closeRevokeDialog"
            />
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15, 50],
              }"
              :headers="headers"
              :items="orders"
              :loading="loadingItems"
              :options.sync="options"
              :items-per-page="itemsPerPage"
              :server-items-length="serverItemsLength"
              disable-sort
            >
              <template v-slot:[`item.ot`]="{ item }">
                <div style="display: flex 1 auto; padding: .5rem 0; margin-right: 1rem;">
                  <div class="font-weight-bold">
                    {{ item.code }} 
                    |
                    <router-link :to="{name:'eq.edit', params:{id: item.equipment.code}}" style="text-decoration: none;">
                      {{ item.equipment.code }}
                    </router-link>
                    |
                    {{ item.client }} | {{ item.office.name }}
                  </div>
                  <div>
                    <i class="mdi mdi-account-supervisor"></i> {{ item.leader }} <span> · </span> 
                    <i class="mdi mdi-calendar"></i> {{ formatDate(item.startDate) }}</div>
                </div>
              </template>
              <template v-slot:[`item.orderType`]="{ item }">
                <v-chip class="ma-2 white--text" color="teal" label>
                  <v-avatar left>
                    <v-icon>{{ orderTypes[item.orderType - 1].icon }}</v-icon>
                  </v-avatar>
                  {{ orderTypes[item.orderType - 1].value }}
                </v-chip>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="colors[item.state - 1].backgroundColor"
                  :text-color="colors[item.state - 1].fontColor"
                  class="pa-3"
                  label
                  small
                >
                  <span class="font-weight-bold">{{
                    $t(`state.${orderState[item.state - 1]}`)
                  }}</span>
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="item.state === 1" >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        class="ma-1"
                        color="light-blue darken-3"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click="editItem(item)"
                      >
                        <v-icon color="white" small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        class="ma-1"
                        color="red"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click="showDeleteDialog(item)"
                      >
                        <v-icon color="white" small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>

                <template v-if="item.state !== 1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        class="ma-1"
                        color="blue"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click="
                          $router.push({
                            name: 'order.images',
                            params: { id: item.id },
                          })
                        "
                      >
                        <v-icon color="white" small>mdi-image</v-icon>
                      </v-btn>
                    </template>
                    <span>Imágenes</span>
                  </v-tooltip>

                  <v-tooltip v-if="item.state === 3 || item.state === 4" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        class="ma-1"
                        color="orange"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click="viewPdf(item)"
                      >
                        <v-icon color="white" small>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Pdf</span>
                  </v-tooltip>                 
                </template>

                <template v-if="item.state === 3">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        class="ma-1"
                        color="teal"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click="showApproveDialog(item)"
                      >
                        <v-icon color="white" small>mdi-thumb-up</v-icon>
                      </v-btn>
                    </template>
                    <span>Aprobar</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        class="ma-1"
                        color="red"
                        depressed
                        fab
                        x-small
                        v-on="on"
                        @click="showRevokeDialog(item)"
                      >
                        <v-icon color="white" small>mdi-thumb-down</v-icon>
                      </v-btn>
                    </template>
                    <span>Rechazar</span>
                  </v-tooltip>
                </template>

                <if-auth>
                    <template v-slot:allowed="{admin}">
                      <v-tooltip v-if="admin === true && item.state === 3 || item.state === 4 || item.state === 5" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            class="ma-1"
                            color="#5e5e5e"
                            depressed
                            fab
                            x-small
                            v-on="on"
                            @click="showRollbackDialog(item)"
                          >
                            <v-icon color="white" small>mdi-arrow-u-left-top</v-icon>
                          </v-btn>
                        </template>
                        <span>Deshacer</span>
                      </v-tooltip>
                    </template>
                  </if-auth> 

              </template>

              <template v-slot:[`item.startDate`]="{ item }">
                <span>{{ formatDate(item.startDate) }}</span>
              </template>
              <template v-slot:[`item.endDate`]="{ item }">
                <span>{{ formatDate(item.endDate) }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { format } from "date-fns";
import { mapActions, mapState } from "vuex";
import EventBus from "@/util/eventbus";
import IfAuth from "@/components/auth/IfAuth";
import ConfirmationDialog from "@/components/dialog/ConfirmationDialog";
import OrderSearchBar from "@/views/order/widgets/order_search_bar";
import { queryToObject } from "@/util/url_utility";

export default {
  name: "OrderList",
  components: { IfAuth, OrderSearchBar, ConfirmationDialog },
  inject: ["orderRepository"],
  data() {
    const queryParams = queryToObject(window.location.search);
    const { page = "1", pageSize = "10" } = queryParams;
    return {
      dialogDelete: false,
      dialogUndo: false,
      dialogApprove: false,
      dialogRevoke: false,
      loadingItems: false,
      serverItemsLength: 0,
      selectedItem: null,
      selectedIndex: -1,
      options: {
        page: parseInt(page, 10),
      },
      itemsPerPage: parseInt(pageSize, 10),
      newFilter: {
        page: parseInt(page, 10),
        pageSize: parseInt(pageSize, 10),
        code: queryParams.code,
        clientName: queryParams.clientName,
        leaderName: queryParams.leaderName,
        officeName: queryParams.officeName,
        state: queryParams.state,
      },
      colors: [
        { backgroundColor: "#D1DADE", fontColor: "black" },
        { backgroundColor: "#F8AC59", fontColor: "white" },
        { backgroundColor: "#0AB1F0", fontColor: "white" },
        { backgroundColor: "#ED5565", fontColor: "white" },
        { backgroundColor: "#6D4C41", fontColor: "white" },
        { backgroundColor: "#000000", fontColor: "white" },
      ],
      headers: [
        {
          text: "Órdenes de trabajo",
          value: "ot"
        },
        {
          text: "Estado",
          value: "status"
        },
        {
          text: "Trabajo a realizar",
          value: "orderType",
          width: 40 * 6 + 32,
        },
        {
          text: "Acciones",
          value: "actions",
          width: 40 * 3 + 32,
        },
      ],
    };
  },
  computed: {
    ...mapState("order", ["orders"]),
    ...mapState("library", ["orderTypes", "orderState"]),
    query() {
      return Object.assign({}, this.newFilter);
    },
  },
  watch: {
    options: {
      handler() {
        const { page, itemsPerPage } = this.options;
        this.newFilter.pageSize = itemsPerPage;
        this.newFilter.page = page;
        this.fetchRecords(this.newFilter);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("order", [
      "fetchOrders",
      "deleteOrder",
      "approveOrder",
      "rejectOrder",
      "rollbackOrder",
    ]),
    formatDate(v) {
      return format(new Date(v), "yyyy-MM-dd HH:mm");
    },
    fetchRecords(newFilter) {
      this.loadingItems = true;
      return this.fetchOrders(newFilter)
        .then((totalItems) => {
          this.serverItemsLength = totalItems;
          this.loadingItems = false;
        })
        .catch(() => {
          this.loadingItems = false;
        });
    },
    clearFilter() {
      this.newFilter.code = null;
      this.newFilter.clientName = null;
      this.newFilter.leaderName = null;
      this.newFilter.localName = null;
      this.newFilter.state = null;
      this.fetchRecords(this.newFilter);
    },
    editItem(item) {
      this.$router.push({ name: "order.edit", params: { id: item.id } });
    },
    deleteItemConfirm() {
      this.$store.commit("LOADER", true);
      this.deleteOrder({
        item: this.selectedItem,
        position: this.selectedIndex,
      })
        .then(() => {
          this.closeDelete();
          EventBus.$emit("SHOW_SNACKBAR", {
            text: "Orden de trabajo eliminada",
            color: "success",
          });
          this.serverItemsLength--;
        })
        .finally(() => this.$store.commit("LOADER", false));
    },
    createItem() {
      this.$router.push({ name: "order.create" });
    },
    showDeleteDialog(item) {
      this.selectedIndex = this.orders.indexOf(item);
      this.selectedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.selectedItem = null;
        this.selectedIndex = -1;
      });
    },
    viewPdf(item) {
      window.open(
        `${process.env.VUE_APP_BASE_API}/pdf/${item.id}/file`,
        "_blank"
      );
    },
    handleApprove() {
      this.$store.commit("LOADER", true);
      this.approveOrder({ orderId: this.selectedItem.id })
        .then(() => {
          this.selectedItem.state = 4;
          this.closeApproveDialog();
          EventBus.$emit("SHOW_SNACKBAR", {
            text: "Orden de trabajo aprobada",
            color: "success",
          });
        })
        .finally(() => {
          this.$store.commit("LOADER", false);
        });
    },
    handleRevoke() {
      this.$store.commit("LOADER", true);
      this.rejectOrder({ orderId: this.selectedItem.id })
        .then(() => {
          this.selectedItem.state = 5;
          this.closeRevokeDialog();
          EventBus.$emit("SHOW_SNACKBAR", {
            text: "Orden de trabajo rechazada",
            color: "success",
          });
        })
        .finally(() => {
          this.$store.commit("LOADER", false);
        });
    },
    showRollbackDialog(item) {
      this.selectedIndex = this.orders.indexOf(item);
      this.selectedItem = item;
      this.dialogUndo = true;
    },
    closeRollbackDialog() {
      this.dialogUndo = false;
      this.$nextTick(() => {
        this.selectedItem = null;
        this.selectedIndex = -1;
      });
    },
    handleRollback() {
      this.$store.commit("LOADER", true);
      this.rollbackOrder({ orderId: this.selectedItem.id })
        .then(() => {
          this.selectedItem.state = 2;
          this.closeRollbackDialog();
          EventBus.$emit("SHOW_SNACKBAR", {
            text: "La operación se realizó con éxito",
            color: "success",
          });
        })
        .finally(() => {
          this.$store.commit("LOADER", false);
        });
    },
    // Approve dialog and confirmation
    showApproveDialog(item) {
      this.selectedIndex = this.orders.indexOf(item);
      this.selectedItem = item;
      this.dialogApprove = true;
    },
    closeApproveDialog() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.selectedItem = null;
        this.selectedIndex = -1;
      })
    },
    // Revoke dialog and confirmation
    showRevokeDialog(item) {
      this.selectedIndex = this.orders.indexOf(item);
      this.selectedItem = item;
      this.dialogRevoke = true;
    },
    closeRevokeDialog() {
      this.dialogRevoke = false;
      this.$nextTick(() => {
        this.selectedItem = null;
        this.selectedIndex = -1;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
